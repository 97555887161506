<template>
  <div class="card">
    <div class="header">
      <img class="icon" src="@/assets/icons/project.png" alt />
      <span class="title">{{ id ? "修改项目" : "新建项目" }}</span>
    </div>

    <div class="steps">
      <div
        class="step"
        v-for="(item, index) in steps"
        :key="item.title"
        :class="step === index ? 'active' : ''"
      >
        <img v-if="step === index" src="@/assets/icons/selected.png" alt />
        <img v-else src="@/assets/icons/not-selected.png" alt />
        <span>{{ item.title }}</span>
      </div>
    </div>

    <div class="form" v-if="step === 0">
      <div class="title">
        <span class="icon"></span>
        <span>基本信息</span>
      </div>

      <div class="label">项目名称</div>
      <input type="text" v-model="name" />
      <div style="padding-top: 12px"></div>

      <div class="label">业主</div>
      <input type="text" v-model="owner" />
      <div style="padding-top: 12px"></div>

      <div class="label">项目地址</div>
      <input type="text" v-model="address" />
    </div>

    <div class="form" v-if="step === 1">
      <div class="title">
        <span class="icon"></span>
        <span>设计信息</span>
      </div>

      <div class="label">设计处理量(吨/天)</div>
      <input type="text" v-model="sys_quantity_proj" />
      <div style="padding-top: 12px"></div>
    </div>

    <div class="button-group">
      <button v-if="step === 0" class="default-button" @click="$router.go(-1)">
        <span>取消</span>
      </button>
      <button v-else class="default-button" @click="prev">
        <span>上一步</span>
      </button>
      <button
        v-if="step < steps.length - 1"
        class="primary-button"
        @click="next"
      >
        下一步
      </button>
      <button v-else class="primary-button" @click="save" :disabled="loading">
        提交
      </button>
    </div>
  </div>
</template>

<script>
import { add, edit, fetchDetail } from "@/api/project";
export default {
  data() {
    return {
      step: 0,
      steps: [
        {
          title: "基本信息",
        },
        {
          title: "设计信息",
        },
      ],
      name: "",
      owner: "",
      address: "",

      sys_quantity_proj: null,

      old_sys_quantity_proj: null,

      id: "",
      detail: {},
      loading: false,
    };
  },

  mounted() {
    const { id } = this.$route.query;
    if (id) {
      this.id = id;
      fetchDetail({
        id,
      }).then((res) => {
        console.log("res", res);
        this.name = res.name;
        this.owner = res.owner;
        this.address = res.address;
        this.detail = res;

        if (Array.isArray(res.prjParamList)) {
          this.sys_quantity_proj = res.prjParamList.find(
            (item) => item.code === "sys_quantity_proj"
          )?.designValue;
          this.old_sys_quantity_proj = this.sys_quantity_proj;
        }
      });
    }
  },

  methods: {
    prev() {
      this.step = this.step - 1;
    },
    next() {
      this.step = this.step + 1;
    },
    save() {
      if (!this.name) {
        this.$message.error("请输入项目名称");
        return;
      }
      if (!this.owner) {
        this.$message.error("请输入业主");
        return;
      }
      if (!this.address) {
        this.$message.error("请输入项目地址");
        return;
      }

      const value = parseFloat(this.sys_quantity_proj);
      if (isNaN(value) || value > 200000 || value < 10000) {
        this.$message.error("请输入正确的设计处理量");
        return;
      }

      let flag = false;
      if (this.id && this.sys_quantity_proj) {
        if (this.old_sys_quantity_proj !== this.sys_quantity_proj) {
          flag = true;
        }
      }
      if (flag) {
        const that = this;
        this.$confirm({
          title: "修改场地或设计信息，该项目所有单体设计将被重置，是否继续？",
          okText: "是",
          okType: "danger",
          cancelText: "否",
          onOk() {
            that.sure();
          },
        });
      } else {
        this.sure();
      }
    },

    sure() {
      this.loading = true;

      let task = add;
      if (this.id) {
        task = edit;
      }
      task({
        id: this.id ? this.id : undefined,
        name: this.name,
        owner: this.owner,
        address: this.address,
        prjParamList: [
          {
            id: this.detail.prjParamList?.find(
              (item) => item.code === "sys_quantity_proj"
            )?.id,
            code: "sys_quantity_proj",
            designValue: this.sys_quantity_proj,
          },
        ],
      })
        .then(() => {
          this.$router.go(-1);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="less" scoped>
.card {
  background: #fff;
  box-shadow: 0px 6px 26px 0px rgba(36, 80, 154, 0.15);
  border-radius: 20px;
  padding: 40px;

  .header {
    display: flex;
    align-items: center;
    margin-bottom: 52px;
    .icon {
      height: 32px;
      margin-right: 12px;
    }
    .title {
      color: #0562fd;
      font-size: 22px;
      font-weight: 500;
    }
  }
}

.steps {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 160px;
  margin-bottom: 80px;

  .step {
    display: flex;
    align-items: center;
    position: relative;
    color: #9c9c9c;

    img {
      height: 28px;
      margin-right: 8px;
    }
    span {
      font-size: 20px;
    }
  }
  .active {
    color: #0562fd;
  }

  .step::after {
    content: "";
    position: absolute;
    left: 100%;
    margin-left: 12px;
    top: 50%;
    width: 136px;
    height: 0;
    border-top: 2px dashed #979797;
  }
  .step:last-child::after {
    display: none;
  }
  .active::after {
    border-color: #0562fd;
  }
}

.form {
  max-width: 810px;
  margin: 0 auto;

  .title {
    display: flex;
    align-items: center;
    color: #0562fd;
    font-size: 18px;
    font-weight: 600;
    line-height: 1em;
    margin-bottom: 32px;

    .icon {
      margin-top: 1px;
      height: 0.8em;
      width: 4px;
      margin-right: 8px;
      background-color: #0562fd;
    }
  }

  .label {
    font-size: 16px;
    font-weight: 400;
    line-height: 1;
    color: #9ca4b1;
    margin-bottom: 12px;
  }

  input {
    display: block;
    width: 100%;
    height: 60px;
    line-height: 60px;
    padding: 0 1em;
    font-size: 18px;
    background: #f8f9fb !important;
    border-radius: 6px;
  }
}

.button-group {
  gap: 36px;
  padding: 80px 0 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  .default-button {
    width: 156px;
    height: 38px;
    border-radius: 6px;
    font-weight: 500;
    color: #0560fd;

    box-shadow: 0px 5px 8px 0px rgba(5, 96, 253, 0.11),
      0px 2px 4px 0px rgba(3, 96, 253, 0.19);

    // background: #fff;
    background: linear-gradient(
      180deg,
      rgba(7, 153, 255, 1),
      rgba(3, 96, 253, 1)
    );
    padding: 1px;
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      flex: 1;
      background-color: #fff;
      line-height: 36px;
      border-radius: 6px;
    }

    // border: 1px solid;
    // border-image: linear-gradient(
    //     180deg,
    //     rgba(7, 153, 255, 1),
    //     rgba(3, 96, 253, 1)
    //   )
    //   1 1;
  }
  .primary-button {
    font-weight: 500;
    color: #fff;
    width: 156px;
    height: 38px;
    line-height: 100%;
    background: linear-gradient(146deg, #0c99ff 0%, #0560fd 100%);
    box-shadow: 0px 5px 8px 0px rgba(5, 96, 253, 0.32);
    border-radius: 6px;
  }
}
</style>